import React, { useEffect, useState } from 'react'

//import { IOrganistTabs } from '../types/index'
import { getCaption } from '../utils'
import { t } from './Translator';
import { Section, SectionUntranslated } from './Layout';
import Image from './Image';

// TODO type content object
const OrganistTabs = ( { content  }: { content: any  } ) => {
    const personalInfo = content.personalInfo
    const discography = content.discography
    const concertCalendar = content.concertCalendar

    // console.log('>>>> personalInfo', personalInfo)
    // console.log('>>>> discography', discography)

    // <OrganistTabs content={organistContent}/>
    useEffect(() => {
        const firstTabButton = document.getElementById("firstTabButton")
        if(firstTabButton) firstTabButton.click();
    }, [])

    const onTabClick = (evt: any, tabId: string) => {
        const tabButtons = document.getElementsByClassName("tab-buttons")
        for (let i = 0; i < tabButtons.length; i++) {
            tabButtons[i].className = tabButtons[i].className.replace(" active", "")
        }
        const tabContents = document.getElementsByClassName("tab-small-content")
        for (let i = 0; i < tabContents.length; i++) {
            const tabElt = tabContents[i]
            if(tabElt && tabElt.style) tabElt.style.display = "none"
        }
        const selectedTabElt = document.getElementById(tabId)
        if(selectedTabElt) selectedTabElt.style.display = "block"
        evt.currentTarget.className += " active"
    }

    return (
        <div style={{ marginTop: '-15px'}}>
            <div className="tab-small">
                <button
                    id="firstTabButton"
                    className="tab-buttons"
                    onClick={(event) => onTabClick(event, 'tabId_01')}
                >
                    {personalInfo.title}
                </button>
                <button
                    className="tab-buttons"
                    onClick={(event) => onTabClick(event, 'tabId_02')}
                >
                    {t(discography.title)}
                </button>
            </div>
            <div id="tabId_01" className="tab-small-content">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="row">
                            <div className="col-12">
                                <Image image={personalInfo.images[0]}/>
                            </div>
                        </div>
                        <div className="spacer-30"></div>
                    </div>
                    <div className="col-lg-7">
                        <Section content={personalInfo.sections[0]}/>
                        <div className="spacer-20"></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-5">
                        <Section content={personalInfo.sections[1]}/>
                        <div className="spacer-20"></div>
                    </div>
                    <div className="col-lg-7">
                        <div className="row">
                            <div className="col-6">
                                <Image image={personalInfo.images[1]}/>
                            </div>
                            <div className="col-6">
                                <Image image={personalInfo.images[2]}/>
                            </div>
                        </div>
                        <div className="spacer-30"></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-5">
                        <div className="row">
                            <div className="col-12">
                                <Image image={personalInfo.images[3]}/>
                            </div>
                        </div>
                        <div className="spacer-30"></div>
                    </div>
                    <div className="col-lg-7">
                        <Section content={personalInfo.sections[2]}/>
                        <span style={{marginTop: '20px'}}>
                            <a href={concertCalendar.link}>
                                {t(concertCalendar.label)}
                            </a>
                        </span>
                        <div className="spacer-20"></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12" style={{display: 'flex'}}>
                        <div>
                           <a href={`mailto:${personalInfo.email}`} target="_blank">
                               <i className="fa fa-envelope-square"/>
                           </a>
                        </div>
                        <div>
                           <a href={personalInfo.facebook} target="_blank">
                               <i className="fa fa-facebook-square"/>
                           </a>
                        </div>
                        <div>
                           <a href={personalInfo.youtube} target="_blank">
                               <i className="fa fa-youtube-square"/>
                           </a>
                        </div>
                        <div className="spacer-20"></div>
                    </div>
                </div>
            </div>
            <div id="tabId_02" className="tab-small-content">
                <Section content={discography.abstract}/>
                <div className="spacer-30"/>
                {discography.images.map((image: any, idx: number) =>
                    <div className="row">
                        <div className="col-sm-4">
                            <Image className="responsive-image-no-cursor" image={image}/>
                            <div className="spacer-30"/>
                        </div>
                        <div className="col-sm-8">
                            <SectionUntranslated html={discography[`cd_${idx + 1}`]}/>
                            <div className="spacer-20"/>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default OrganistTabs
