import React, {useEffect} from 'react'

// import Layout from '@components/Layout'
import Layout, {Section} from '../components/Layout'
import Image from '../components/Image'
import { t } from '../components/Translator'
import OrganistTabs from '../components/OrganistTabs';

// @ts-ignore
import organsContent from '../../content/organs.yaml'
// @ts-ignore
import organistContent from '../../content/organist.yaml'

const OrgansPage: React.FC = () => {
    const organ_1 = organsContent.organ_1
    const organ_2 = organsContent.organ_2

    useEffect(() => {

        const Tab = require('bootstrap.native/dist/components/tab-native')
        const infoTabs = document.getElementById('info-tabs')

        // todo: no idea what the qualifiedName 'A' stands for
        // @ts-ignore
        const infoTabsCollection = infoTabs.getElementsByTagName('A')
        Array.from(infoTabsCollection).map(
            tab => new Tab( tab, { height: true } )
        )

        return () => {
            // todo: research dispose of tabs? Without risk on memory leak?
            // if(!!carousel) carousel.dispose()
        }
    }, [])

    return (
        <Layout head={organsContent.head} banner={organsContent.banner}>
            <div className="row">
                <div className="tab">
                    <ul id="info-tabs" className="nav nav-tabs" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" id="organ-1-tab" href="#organ-1"
                               aria-controls="organ-1" aria-selected="true" role="tab">{t(organ_1.title)}</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="organ-2-tab" href="#organ-2" data-toggle="tab" data-height="true"
                               aria-controls="organ-2" aria-selected="false" role="tab">{t(organ_2.title)}</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="organist-tab" href="#organist" data-toggle="tab" data-height="true"
                               aria-controls="organist" aria-selected="false" role="tab">{t(organistContent.title)}</a>
                        </li>
                    </ul>
                    <div className="tab-content tabs">
                        <div role="tabpanel" className="tab-pane fade active show" aria-labelledby="organ-1-tab" id="organ-1">
                            <div className="row">
                                {/*<div className="col-sm-4 col-sm-push-8">*/}
                                <div className="col-lg-7 order-lg-2">
                                    <Section content={organ_1.sections[0]}/>
                                    {/*<div className="spacer-10"></div>*/}
                                </div>
                                <div className="col-lg-5 order-lg-1">
                                    <Image image={organ_1.images[0]}/>
                                    <div className="spacer-40"></div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-5">
                                    <Section content={organ_1.sections[1]}/>
                                    <div className="spacer-20"></div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="row">
                                        <div className="col-6">
                                            <Image image={organ_1.images[1]}/>
                                        </div>
                                        <div className="col-6">
                                            <Image image={organ_1.images[2]}/>
                                        </div>
                                    </div>
                                    <div className="spacer-30"></div>
                                </div>
                            </div>
                            <div className="row">
                                {/*<div className="col-sm-4 col-sm-push-8">*/}
                                <div className="col-lg-7 order-lg-2">
                                    <Section content={organ_1.sections[2]}/>
                                    {/*<div className="spacer-10"></div>*/}
                                    <span style={{marginTop: '20px'}}>
                                        {/*<a href="https://klais.de/m.php?sid=612" target="_blank">*/}
                                        <a href={t(organ_1.learn_more_link)} target="_blank">
                                            {t(organsContent.learn_more)}
                                        </a>
                                    </span>
                                </div>
                                <div className="col-lg-5 order-lg-1">
                                    <Image image={organ_1.images[3]}/>
                                    <div className="spacer-30"></div>
                                </div>
                            </div>
                        </div>
                        <div role="tabpanel" className="tab-pane fade" aria-labelledby="organ-2-tab" id="organ-2">
                            <div className="row">
                                {/*<div className="col-sm-4 col-sm-push-8">*/}
                                <div className="col-lg-7 order-lg-2">
                                    <Section content={organ_2.sections[0]}/>
                                    {/*<div className="spacer-10"></div>*/}
                                </div>
                                <div className="col-lg-5 order-lg-1">
                                    <Image image={organ_2.images[0]}/>
                                    <div className="spacer-40"></div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-8">
                                    <Section content={organ_2.sections[1]}/>
                                    <div className="spacer-20"></div>
                                </div>
                                <div className="col-lg-4">
                                    <Image image={organ_2.images[1]}/>
                                    <div className="spacer-30"></div>
                                </div>
                            </div>
                            <div className="row">
                                {/*<div className="col-sm-4 col-sm-push-8">*/}
                                <div className="col-lg-7 order-lg-2">
                                    <Section content={organ_2.sections[2]}/>
                                    {/*<div className="spacer-10"></div>*/}
                                    <span style={{marginTop: '20px'}}>
                                        {/*<a href="https://www.orgel-schumacher.com/nl/projekte/restauraties/brugge-kathedraal" target="_blank">*/}
                                        <a href={t(organ_2.learn_more_link)} target="_blank">
                                            {t(organsContent.learn_more)}
                                        </a>
                                    </span>
                                </div>
                                <div className="col-lg-5 order-lg-1">
                                    <Image image={organ_2.images[2]}/>
                                    <div className="spacer-30"></div>
                                </div>
                            </div>
                        </div>
                        <div role="tabpanel" className="tab-pane fade" aria-labelledby="organist-tab" id="organist">
                            <OrganistTabs content={organistContent}/>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default OrgansPage